@font-face {
	font-family: 'e-Ukraine-Light';
	src: url('fonts/e-Ukraine-Light.eot');
	src: local('e-Ukraine Light'), 
		url('fonts/e-Ukraine-Light.eot?#iefix') format('embedded-opentype'),
		url('fonts/e-Ukraine-Light.woff2') format('woff2'),
		url('fonts/e-Ukraine-Light.woff') format('woff'),
		url('fonts/e-Ukraine-Light.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'e-Ukraine-Regular';
	src: url('fonts/e-Ukraine-Regular.eot');
	src: local('e-Ukraine Regular'), 
		url('fonts/e-Ukraine-Regular.eot?#iefix') format('embedded-opentype'),
		url('fonts/e-Ukraine-Regular.woff2') format('woff2'),
		url('fonts/e-Ukraine-Regular.woff') format('woff'),
		url('fonts/e-Ukraine-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}