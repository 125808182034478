.contacts {
	padding: 20px 10px 25px;
	background-color: $cards_bg_color;
	border-radius: 10px;
	margin-top: 50px;
	@include sm {
		margin-top: 30px;
	}
	&__title {
		font-size: 18px;
		line-height: 22px;
		color: $cards_title_color;
		padding-bottom: 10px;
		border-bottom: 1px solid rgba(0, 0, 0, .05);
		@include sm {
			padding-bottom: 0;
			border-bottom: none;
		}
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin-top: 12px;
		@include sm {
			margin-top: 5px;
		}
		a {
			flex: none;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			margin-top: 10px;
			margin-right: 10px;
			border-radius: 50%;
			position: relative;
			z-index: 1;
			&:last-child {
				margin-right: 0;
			}
			&:after {
				display: block;
				content: '';
				width: 100%;
				height: 100%;
				background-color: $accent_bg_color;
				border-radius: 50%;
				position: absolute;
				top: 0;
				left: 0;
				transition: all .3s ease-in-out;
				z-index: -1;
			}
			svg {
				fill: $cards_btn_icon_color;
				transition: all .3s ease-in-out;
				&.facebook-icon {
					width: 10px;
					height: 20px;
				}
				&.twitter-icon {
					width: 20px;
					height: 16px;
				}
				&.linkedin-icon {
					width: 20px;
					height: 20px;
				}
				&.telegram-icon {
					width: 22px;
					height: 19px;
				}
				&.youtube-icon {
					width: 21px;
					height: 15px;
				}
				&.instagram-icon {
					width: 20px;
					height: 20px;
				}
			}
			&:hover,
			&:active {
				svg {
					opacity: .8;
				}
				&:after {
					transform: scale(1.1);
				}
			}
		}
	}
}