.footer {
	padding: 60px 0 40px;
	@include sm {
		padding: 50px 0 20px;
	}
	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__text {
		font-family: e-Ukraine-Light;
		font-size: 12px;
		line-height: 140%;
		margin-bottom: 10px;
		text-align: center;
	}
	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		font-family: e-Ukraine-Light;
		font-size: 12px;
		line-height: 140%;
		text-align: center;
		padding: 0 22px;
		border: 1px solid rgba(207, 207, 207, .5);
		border-radius: 40px;
		cursor: pointer;
		transition: all .3s ease-in-out;
		&:hover,
		&active {
			color: $accent_color;
			background-color: $accent_bg_color;
			border-color: $accent_bg_color;
		}
	}
}