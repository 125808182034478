/* NORMALIZE */
/* ================================================ */
html, body, div, span, applet, main, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; }

/* HTML5 display-role reset for older browsers */
article, aside, details, main, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }

body { margin: 0px; padding: 0px; font-family: Arial, Tahoma, sans-serif; line-height: 1; background-color: #ffffff; height: 100%; position: relative; font-size: 14px; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after { content: ""; content: none; }

q:before, q:after { content: ""; content: none; }

table { border-collapse: collapse; border-width: 0px; padding: 0px; margin: 0px; }

html { height: 100%; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; }

input, textarea { color: #333; outline: none; border-radius: 0; -moz-border-radius: 0; -webkit-border-radius: 0; -webkit-appearance: none; }

input[type="button"], input[type="submit"] { cursor: pointer; -webkit-appearance: none; outline: none; }

button { cursor: pointer; -webkit-appearance: none; outline: none; }

td, form { margin: 0px; padding: 0px; }

a { color: #000100; -webkit-transition: all 0.3s linear; -o-transition: all 0.3s linear; transition: all 0.3s linear; text-decoration: none; outline: none; -webkit-tap-highlight-color: transparent; -webkit-tap-highlight-color: transparent; }

* { -webkit-box-sizing: border-box; box-sizing: border-box; }
*:before, *:after { -webkit-box-sizing: border-box; box-sizing: border-box; }

.clearfix:after { content: ""; display: block; height: 0; clear: both; visibility: hidden; }

/*input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}*/

input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }