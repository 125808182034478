.about {
	padding: 20px 10px 30px;
	background-color: $cards_bg_color;
	border-radius: 10px;
	margin-top: 50px;
	margin-bottom: 50px;
	position: relative;
	@include sm {
		margin-top: 30px;
		margin-bottom: 30px;
		padding: 20px 10px 25px;
	}
	&__arrow {
		display: none;
		transition: all .3s ease-in-out;
		.collapse & {
			@include sm {
				display: block;
				width: 8px;
				height: 12px;
				fill: none;
				stroke: #999999;
				position: absolute;
				top: 25px;
				right: 25px;
				transform: rotate(90deg);
			}
		}
		.collapse.open & {
			@include sm {
				transform: rotate(270deg);
			}
		}
	}
	&__title {
		font-size: 18px;
		line-height: 22px;
		color: $cards_title_color;
		padding-bottom: 10px;
		margin-bottom: 20px;
		border-bottom: 1px solid rgba(0, 0, 0, .05);
		@include sm {
			padding-bottom: 0;
			margin-bottom: 15px;
			border-bottom: none;
		}
	}
	&__content-wrap {
		position: relative;
		transition: all .3s ease-in-out;
		@include sm {
			max-height: 102px;
			overflow: hidden;
		}
		.collapse &:after {
			@include sm {
				display: block;
				content: '';
				width: 100%;
				height: 50px;
				position: absolute;
				left: 0;
				bottom: 0;
				background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
				z-index: 1;
			}
		}
		.collapse.open &:after {
			@include sm {
				display: none;
			}
		}
	}
	&__content {
		max-width: 500px;
		p {
			font-size: 12px;
			line-height: 140%;
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}