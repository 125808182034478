.current-purpose {
	padding: 10px 0 20px;
	color: $accent_color;
	background-color: $accent_bg_color;
	border-radius: 10px;
	margin-bottom: 20px;
	@include sm {
		margin-bottom: 10px;
	}
	&__label {
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: e-Ukraine-Light;
		font-size: 10px;
		line-height: 12px;
		margin-bottom: 11px;
		&:before,
		&:after {
			display: block;
			content: '';
			flex: 1 0 auto;
			height: 1px;
			background-color: $accent_color;
			opacity: .25;
		}
		span {
			flex: none;
			opacity: .7;
			margin: 0 15px;
		}
	}
	&__value {
		font-size: 46px;
		line-height: 55px;
		text-align: center;
		padding: 0 30px;
		@include sm {
			font-size: 30px;
			line-height: 36px;
			padding: 0 20px;
		}
	}
	&__description {
		font-family: e-Ukraine-Light;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		margin-top: 8px;
		padding: 0 100px;
		@include sm {
			font-size: 10px;
			line-height: 12px;
			margin-top: 5px;
			padding: 0 20px;
		}
	}
}

.payment-section {
	margin-bottom: 50px;
	@include sm {
		margin-bottom: 30px;
	}
}

.payment-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 60px;
	padding: 10px;
	margin-bottom: 20px;
	border-radius: 10px;
	background-color: $cards_bg_color;
	cursor: pointer;
	transition: all .3s ease-in-out;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	@include sm {
		flex-wrap: wrap;
		padding: 10px 0;
		margin-bottom: 10px;
	}
	&--link {
		@include sm {
			flex-wrap: nowrap;
			padding: 10px;
		}
	}
	&__title {
		flex: 1 0 auto;
		max-width: 50%;
		display: flex;
		align-items: center;
		font-size: 14px;
		line-height: 17px;
		margin-right: 10px;
		color: $cards_title_color;
		@include sm {
			max-width: unset;
			width: 100%;
			padding: 0 10px;
		}
		.payment-item--link & {
			max-width: unset;
			margin-right: 0;
			@include sm {
				width: unset;
				padding: 0;
			}
		}
		img {
			flex: none;
			display: block;
			width: 40px;
			height: 40px;
			margin-right: 9px;
		}
	}
	&__data {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		@include sm {
			width: 100%;
			justify-content: space-between;
			padding: 10px 10px 0;
			margin-top: 10px;
			border-top: 1px solid rgba(0, 0, 0, .05);
		}
		.payment-item--link & {
			@include sm {
				width: unset;
				border-top: none;
				padding: 0;
				margin-top: 0;
			}
		}
	}
	&__info {
		text-align: right;
		@include sm {
			text-align: left;
		}
	}
	&__value {
		display: block;
		font-size: 14px;
		line-height: 17px;
		color: #000000;
		word-break: break-word;
		@include sm {
			font-size: 12px;
			line-height: 14px;
		}
	}
	&__description {
		display: block;
		font-size: 10px;
		line-height: 12px;
		color: #999999;
		margin-top: 5px;
	}
	&.active .tooltip-copy {
		display: flex;
	}
	&:hover,
	&:active {
		background-color: $cards_bg_color_hover;
		box-shadow: 0px 5px 20px rgba(0, 0, 0, .05);
	}
	&:hover .btn:after,
	&:active .btn:after{
		transform: scale(1.1);
	}
}

.payment-multi-block {
	background-color: $cards_bg_color;
	border-radius: 10px;
	margin-bottom: 20px;
	transition: all .3s ease-in-out;
	@include sm {
		margin-bottom: 10px;
	}
	&__head {
		padding: 10px 40px 10px 10px;
		position: relative;
		cursor: pointer;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		svg {
			display: block;
			width: 8px;
			height: 12px;
			fill: none;
			stroke: #999999;
			position: absolute;
			top: calc(50% - 4px);
			right: 25px;
			transform: rotate(90deg);
			transition: all .3s ease-in-out;
		}
		&.active {
			svg {
				transform: rotate(270deg);
			}
		}
	}
	&__title {
		display: flex;
		align-items: center;
		font-size: 14px;
		line-height: 17px;
		color: $cards_title_color;
		img {
			flex: none;
			display: block;
			width: 40px;
			height: 40px;
			margin-right: 9px;
		}
	}
	&__body {
		padding: 0 10px;
		border-top: 1px solid rgba(0, 0, 0, .05);
		display: none;
	}
	&:hover,
	&:active {
		background-color: $cards_bg_color_hover;
		box-shadow: 0px 5px 20px rgba(0, 0, 0, .05);
	}
}

.multi-block-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 50px;
	padding: 5px 0;
	border-bottom: 1px solid rgba(0, 0, 0, .05);
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	&:first-child {
		padding-top: 10px;
	}
	&:last-child {
		padding-bottom: 15px;
		border-bottom: none;
	}
	&__label {
		display: block;
		font-size: 10px;
		line-height: 12px;
		color: #999999;
		margin-bottom: 3px;
	}
	&__value {
		display: block;
		font-size: 14px;
		line-height: 17px;
		color: #000000;
		word-break: break-word;
		@include sm {
			font-size: 12px;
			line-height: 14px;
		}
	}
	&.active .tooltip-copy {
		display: flex;
	}
	&:hover .btn:after,
	&:active .btn:after{
		transform: scale(1.1);
	}
}

.btn {
	flex: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	margin-left: 14px;
	border-radius: 50%;
	position: relative;
	z-index: 1;
	svg {
		fill: none;
		stroke: $cards_btn_icon_color;
	}
	&--copy {
		svg {
			width: 17px;
			height: 17px;
		}
	}
	&--link {
		svg {
			width: 8px;
			height: 12px;
			transform: translateX(1px);
		}
	}
	&:after {
		display: block;
		content: '';
		width: 100%;
		height: 100%;
		background-color: $accent_bg_color;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: 0;
		transition: all .3s ease-in-out;
		z-index: -1;
	}
}

.tooltip-copy {
	display: none;
	align-items: center;
	justify-content: center;
	height: 30px;
	text-align: center;
	padding: 0 15px;
	background-color: #ffffff;
	color: #999999;
	box-shadow: 0px 0px 13px rgba(0, 0, 0, .1);
	border-radius: 20px;
	position: absolute;
	top: 5px;
	right: 50px;
}